<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="top">
        <div class="picture">
          <img :src="data.img" alt="" />
        </div>
        <div class="des">
          <a-typography-title :heading="2">
            {{ data.title }}
          </a-typography-title>
          <!-- <a-typography-title :heading="5">
            {{ data.time }}
          </a-typography-title> -->
          <!-- <a-typography-title v-if="data.title1" :heading="4">
            {{ data.desc }}
          </a-typography-title> -->
          <a-typography-title :heading="6">
            {{ data.desc }}
          </a-typography-title>
        </div>
      </div>
      <a-divider />
      <div class="des-cont"></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { articleDetail } from '@/api/homepage.js'
import { Notification } from '@arco-design/web-vue'
const route = useRoute()

let detailId = route.query.id
const data = ref({})
const getDetail = async () => {
  const res = await articleDetail({
    id: Number(detailId)
  })
  // console.log(res)
  if (res.code === 0) {
    data.value = res.data
    let boxCont = document.querySelector('.des-cont')
    // console.log(boxCont)
    boxCont.innerHTML = res.data.content
  } else {
    Notification.error(res.message)
  }
}
getDetail()
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku11.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1200px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .top {
    display: flex;
    align-items: center;

    .picture {
      margin-right: 48px;

      img {
        width: 375px;
        height: 360px;
        border-radius: 8px;
        border: 1px solid var(--color-neutral-1);
      }
    }
  }
}

.des-cont p>a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 4px;
  color: rgb(var(--link-6));
  font-size: 14px;
  line-height: 1.5715;
  text-decoration: none;
  background-color: transparent;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  transition: all .1s cubic-bezier(0, 0, 1, 1);
}
</style>
